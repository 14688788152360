import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "color"
    }}>{`Color`}</h2>
    <p>{`Inputs come in two different colors. The default input color is `}<inlineCode parentName="p">{`$field-01`}</inlineCode>{` and is used on `}<inlineCode parentName="p">{`$ui-background`}</inlineCode>{` and `}<inlineCode parentName="p">{`$ui-02`}</inlineCode>{` page backgrounds. The `}<inlineCode parentName="p">{`--light`}</inlineCode>{` version input color is `}<inlineCode parentName="p">{`$field-02`}</inlineCode>{` and is used on `}<inlineCode parentName="p">{`$ui-01`}</inlineCode>{` page backgrounds.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`SCSS`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--dropdown`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$field-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--dropdown`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$ui-04`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--dropdown--light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$field-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--dropdown-list`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$ui-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--dropdown-text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:placeholder`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--dropdown-item`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$hover-ui`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--dropdown-item`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--dropdown__arrow`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$icon-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--list-box__selection--multi`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$ui-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--dropdown--open`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`box-shadow`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`0 4px 8px 0 rgba(0,0,0,0.10);`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.52173913043479%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAABYUlEQVQoz42Sy2rCUBCGE6jSrlx106zctHQlSp+hJZW+Qt7BnRvfSLPwFUpXYmMKBUVyw3jJvUmgDVVy+p+QlC6kx4HDcIbJd/5/Jhx3JAaDAceKLMu4k0KSpCKLosj3er1Gv99vmKZ5RmuEkN9T3ZkRRRFP836/v1ytVi+GYbzruq54nveaJMlbGIbPcRxf0R5kngl0XbcCn282mwfbtp8A7gL4iFo3CIJ7QC9oDzJb4Wg0KvJwOKzLstxGvhuPx63pdHqjKMrtZDK5VlW1RntmsxkbCIuFjfV6Lczn80/YJYvFgliWRRzHIZqmfaHWpD3IbMuwxpeWhd1uF/m+TzC7A+Z1SNOUfCC2223zb++/ASVFE2DCcrmMoZgAkOOeQzXBPamA2D4bCFt8OXABCwkAyaH2uzw5lhJiWc3yUTYQkEppDR+2oKaDR9r04A/oANKCyjrtwfZP+7krKGM0R+s/RXAS4WLft3cAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Normal and opened dropdowns",
          "title": "Normal and opened dropdowns",
          "src": "/static/bfb8897d2a7eb2bb823be0d0488dacfc/fb070/dropdown-style-1.png",
          "srcSet": ["/static/bfb8897d2a7eb2bb823be0d0488dacfc/d6747/dropdown-style-1.png 288w", "/static/bfb8897d2a7eb2bb823be0d0488dacfc/09548/dropdown-style-1.png 576w", "/static/bfb8897d2a7eb2bb823be0d0488dacfc/fb070/dropdown-style-1.png 1152w", "/static/bfb8897d2a7eb2bb823be0d0488dacfc/c3e47/dropdown-style-1.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">Example of a closed and opened dropdown</Caption>
    <h3 {...{
      "id": "interactive-states"
    }}>{`Interactive states`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`SCSS`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$focus`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--dropdown-item:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$hover-ui`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--dropdown-item:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--form-requirement`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$support-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`[data-invalid]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$support-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--label:disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$disbaled-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--dropdown:disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$disbaled-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--dropdown-text:disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$disbaled-02`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`Active:`}</strong>{` Placeholder text should remain when the user clicks into the text input and gets a cursor. Once the user starts typing, the hint text is replaced with the user input text.`}</p>
    <p><strong parentName="p">{`Help text:`}</strong>{` Help text appears below the label when the input is active. Help text remains visible while the input is focused and disappears after focus away.`}</p>
    <p><strong parentName="p">{`Error:`}</strong>{` Error messages appear below the input field and are always present while invalid.`}</p>
    <p><strong parentName="p">{`Disabled:`}</strong>{` Disabled state should has a `}<inlineCode parentName="p">{`.not-allowed`}</inlineCode>{` cursor on hover.`}</p>
    <h2 {...{
      "id": "typography"
    }}>{`Typography`}</h2>
    <p>{`All dropdown text should be set in sentence case, with only the first word in a phrase and any proper nouns capitalized. Dropdown options should not exceed three words.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-size`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-weight`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type style`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--dropdown-text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$body-short-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--dropdown-link`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$body-short-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$label-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--form-requirement`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12 / 0.75`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$label-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "structure"
    }}>{`Structure`}</h2>
    <p>{`Dropdowns have two states, open and closed. An open and closed dropdown should be the same width and appropriately fit the design, layout, and content. The height of a closed dropdown stays consistent while the height of an open dropdown will vary based on the amount of options it has. Please note the various color differences for closed and open dropdowns.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing tokens`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8 / 0.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--dropdown`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40 / 2.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--dropdown-text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--dropdown__arrow`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-right, padding-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--dropdown`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--dropdown-item`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40 / 2.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.54347826086956%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsSAAALEgHS3X78AAACTElEQVQ4y61Tz2sTQRTeTC8FG2pREA/etGoOttoq8aJtkYIWQfCg/4AgBipeRAWttiCmqCAiir9Q8BfEWGsCCmKL1lSKQZukaWpcY3c3O7O72exu2tWYujDOrFoKMdkefPD4Pma+/ea9fTMMQyK29gAFJkEw2nnG5gN3WCbFmyCfQ0CECBR0HcizM2CMF0EoErM19zGe/7Ys4pU2KsRFYlYx/p5CTUc7T9dQfvv8m2XDbyd3FXRlC4TIO6Mb3pxpeqNZ6H0xMl5HNYFKFS5cjO44XkfOBg9C75o+cDyCgsB/5ThOy6scNAwhkuGkJ5GPLVR7E2PXolv2nOyp7T7mX57b71/yfl9fvbCn133Bd3ZpV0//qqY+fy3VdJ04V71liqnmIzZPensZI1FiEpsPu+MbDq1MNPtWJFu6G7K7T9W83v5bY209ysTXVWg5tmDjGYsdh3LvU6bsd/0z7obTNl56lG4YGk3tJUPZJoqwXdP0tqJptn3J59tvIWQPZVBRnK9DWFYAxetKrpE1DGySzGsa/l4sYmxZWJ6bwzcktJFqnuYU4FjhgJi1DS/z3Jq4KFqqLFtGoWDphmER858ZYn41/XkT1QQFAThWGJie/mPIN46xLOYyGSxJEpZkGYs8jydEET8Mj3RQTX+1azNfYVZwUbzC86vHBaGkQFjSdf2HRnKWJKuq1rWptN3yYyg6t3zw1UsbO4IBdzA50Wqq6nooIQ9EyPON8MHJZOvO56F6qvENDy3unWKM/4umTEi4CyEEFEWxk3K6Vs30F/8QYzg2ac8KAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Structure and spacing for a closed dropdown",
          "title": "Structure and spacing for a closed dropdown",
          "src": "/static/88d252168a2f43ecd0c04b941f84db9e/fb070/dropdown-style-2.png",
          "srcSet": ["/static/88d252168a2f43ecd0c04b941f84db9e/d6747/dropdown-style-2.png 288w", "/static/88d252168a2f43ecd0c04b941f84db9e/09548/dropdown-style-2.png 576w", "/static/88d252168a2f43ecd0c04b941f84db9e/fb070/dropdown-style-2.png 1152w", "/static/88d252168a2f43ecd0c04b941f84db9e/c3e47/dropdown-style-2.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">Structure and spacing measurements for dropdown | px / rem</Caption>
    <h2 {...{
      "id": "multi-select-dropdown"
    }}>{`Multi-select dropdown`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing tokens`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--list-box`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40 / 2.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--list-box__menu-item`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40 / 2.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--list-box__field`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left, padding-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--checkbox-label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--list-box__menu-icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left, padding-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--list-box__selection--multi`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24 / 1.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--list-box__selection--multi`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "92.2554347826087%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsSAAALEgHS3X78AAADF0lEQVQ4y62T20sUcRTHf+tqWxR2QUqtMIKopIcICh8i6LHXoD+hooceukAISVop9qCGlsKWd23NS9FFF3TFjB5cdUNN1529zn3cHWdmd2ZXV9fdX7+Z1V3FCgIPfOacuXy/c37zOwPADsRgz7f0yfTpm2DsSrFerRuKLfvNw7PXBJ69xLBckSRJRZFIpCgcDqdQEMsIRpaLxinmovmXy6BqzfGo5qUdbJcf7oMAZHS2Wk5NEqSPxnHG5/NRgUCACoVCFDKmgsGghlorKBOiyP7wEu5B03ChqjVCmDTcHNlN1VlXuxsPQB+bxfO8we3xGDAMMxAEkcLh9e6G4ciuB+b+7AvtzUfv3366B2beyASQBNNn1jt8AXYwVMN2GWq1YwbmVdQ1NtSZOl77g8GXHMdWLSws1MiyXK0oioaMWEKwslxjE4WKfil4UNUOhUK6VIclaP3IUjfilY6X19X3vjU29TIk3eVxud75cF+7IAgdoihqCAhZktqcgUB3P473GEnisGrYRhC6dJt5Jzcq3Y4suer6lFZXFnTq+1qobLWGEOrWyfgL6Zfv1W81bZl0ajcxCI88qqy2NdTVjs/Z7daJiQmrzWazop22Op1OLWMouzFs7KfLOTGAOb53EmSuqu2iSV1qbD6ylGbYAeP5Q7MzkCQINBlhiIZaIxqNplhGrC4vw8WVFTjG87BT4gtU7RfMk5Hq0ORyaYa1Xl++2W6PzLtcCQLHYwzDrNE0vQUKwdJ0zEHTiRGCCH/osZxTtfXoE6Q6fO/xaIZGvz//68yMModhcYqiVjmOi7EsG1PzBizCz3GrTo6Lj1KU0vd5tFDVvtls+IlhNMNXJHlsShRhGC0rkUjAf8USYlpRoEniT6jaATeeXnKrM7kp5Y75Q4Nud4uHJFv5QKAJzd82FgWhOSSKTR6eb7NQVKtx3qHNYTuJ67b9y+D82Yz/nLo/zy3qfnOtgyur+pCi6ONra/pYLLaFNXRtKRrNRM/p1+d0mwcAz54k8/NSAEofA3DnVvI8J2f72/Nyk/neXQDKStB3KktrUfwGPriOgYf/PCAAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Structure and spacing for a multi-select dropdown",
          "title": "Structure and spacing for a multi-select dropdown",
          "src": "/static/4e48faf2ff5d747708f967afc1baf16a/fb070/dropdown-style-3.png",
          "srcSet": ["/static/4e48faf2ff5d747708f967afc1baf16a/d6747/dropdown-style-3.png 288w", "/static/4e48faf2ff5d747708f967afc1baf16a/09548/dropdown-style-3.png 576w", "/static/4e48faf2ff5d747708f967afc1baf16a/fb070/dropdown-style-3.png 1152w", "/static/4e48faf2ff5d747708f967afc1baf16a/c3e47/dropdown-style-3.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">
  Structure and spacing measurements for a multi-select dropdown | px / rem
    </Caption>
    <h2 {...{
      "id": "inline-dropdown"
    }}>{`Inline dropdown`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--list-box.bx--list-box--inline`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32 / 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--list-box__menu-item`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40 / 2.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--list-box__menu-item`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-right, padding-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--checkbox-label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--list-box__menu-icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left, padding-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "122.41847826086956%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAYCAYAAAD6S912AAAACXBIWXMAAAsSAAALEgHS3X78AAADlUlEQVQ4y51VW0wcVRg+s0iAVoltTQGj0Ri3XC23UEHxgjFVH2yblAd2IWmwtqS0Vk2b9MFL+oAxPpiamJhIbZtgDOW2CxTaUERtSsulWy5bxJ3dnZkdZmZhZ3dmloWysQsczzndJSa6zOqfnJz/n/nmm+8/8505AKCYzj4CYrPdeBiMvPghiMWzzxtBZlbWRv0NhCChiJFuFhnWjv9GhmbqTukHjyINj1y8dO25+t7eF9DlrdvT0h4/OTX18ieSlI9xddcHEiYE16tOJ+G84WL7jjetXU/iPJ2ikvYODux669ehZ3BddPmn/9dy41TI8LeSAsanDXAdGmY4llwIBAL6CnGc6bL8E/TRiR3gkCktViqcuPGMVHo2vtLL0wyZv53nUvusrvpbN269axsbfbtXEE7/FvAfDS8vV8grS4RY8z9UOGl8Lz6hledJm987nfkzahD6ZBmGFhfh8tISXLl/H2qaBiXGU4AxshowbOoOfLPN7SKEzTSd+zNNQ/vMzKrL5VpjWXYVjXWGYcK/t14rxxgWQop4N/tofNI+SSKELYKQa0PqBEla11QVhkIhuIRUBoPBiE/0EvsEFIUojKsS32hFb8V5V/9wyW0nA3lBiCiBwComQmMNtRxekH05GOOX/QZd6zQODZL8YGtr+nfjttfdolgizs0VzwkCGR6eL3LQdCrGuBgG6LaMQyzeG0uT42FGOS6xLfvFrJu0fBvCdNPxUz2HzbVHJu7Z8zra2srae3pKBh0OY29ITY6ut/6O6RaiH0Xy5g3Y//hTFEVZURR1ORRSpGBQGZNlxcJ6yEfp8XoNkzk6tmln3ITwvMORe4Nj4SyyDsdxUOR56BAE+Avvgd1dQ69izNdR22waV9wcIbw0L+WMShK2TQTt2ciipkUEVV0d8fkiFtpdiDGWoKJv7M6obTpuThTbfX7oVxQYDofhWiQCtQcP4DTyonVhfjdZQ+98fNvYcx4atAYGSf3pj5bM5pvDJ2x2u4n3eKp9klRtY1nz+cmJY58NDz+BMWfHR/VbvpDYPxmIekdBTCFAQA0Aw91JdftrZrO5/MCBynNX+1PfyMhI+7izM+tzni/8StMew898qSiJEaJ3Uy0jXEaZyfR+/v59Ve80NaUgSMrBCz88VXfX9kqjk95GfsBOWr+VPbnRXfKSMelfARWlqWCLgdIl2lCIoiazksy1eQXUObxeDScBlbkTidc/Qv8COGwAb7/g5I4AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Structure and spacing for inline dropdown",
          "title": "Structure and spacing for inline dropdown",
          "src": "/static/2f26da16e00a7a59ace6bc6b32e2cdbd/fb070/dropdown-style-5.png",
          "srcSet": ["/static/2f26da16e00a7a59ace6bc6b32e2cdbd/d6747/dropdown-style-5.png 288w", "/static/2f26da16e00a7a59ace6bc6b32e2cdbd/09548/dropdown-style-5.png 576w", "/static/2f26da16e00a7a59ace6bc6b32e2cdbd/fb070/dropdown-style-5.png 1152w", "/static/2f26da16e00a7a59ace6bc6b32e2cdbd/c3e47/dropdown-style-5.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">Structure and spacing for inline dropdown | px / rem</Caption>
    <h3 {...{
      "id": "inline-dropdown-states"
    }}>{`Inline dropdown states`}</h3>
    <p>{`Inline select has two different states; one for mouse hover and one for keyboard focus.`}</p>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "80.97826086956522%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsSAAALEgHS3X78AAACuklEQVQ4y2NggIKsrCwGcsGdO3dQOcnJyTBDOTMzMwWePXvGeO7cOaYbN24w3b59mwmohunWrVtM8+fPZ963bx/TtWvXmEDyly5dAokzgvRev36dAagWYuDSpUvBBm7atIn10KFDHP///2cB4SdPnrAC5VmBmljv37/PClIDFGd+/fo1K0j+58+fIHFmkPiLFy8gBmIBzEgYBcjIyEhJSkpyIaljglqC6mUlJSUw28jIiFFWVpYPqBGEeU1MTJhKS0vhGhQUFIpVVFR0geKsGhoagpqamjypqakM8vLyDFpaWqhhGRUVRXakdHR0oLqwpaUFzJ4wYQJff3+/xOzZs4WAAc8FDBMuoDw7ELMB2Rxr1qzhPn78ONfNmze5Ll++zAWMCO67d++ygfSCwg/IRkQ5MFAZcbkAFMP4XPjy5UtUvcCYAmt4+PChCzCp9ABd1wikWx8/flzz6NEjEZAckNbt7u7u2r9/fxNQffPVq1ebgK7qefr0aRhIHqiWEciGuBBoEBNUMALonW1AvByoYT3QS6uAaVIaKmfd2Ni4dc+ePauABq4FWroc6O0jQP1VIHmgeiZgMkO4EiXq0QAwATPi8zKKXlBg9vT2gdmzZ8/hnjZ1qsjMmTMEt23dwvPs6VOO6zduASPlNtudu/fYp06dyr1zxzaua1evcJ4+dZLz1s3rnI8eP2aDGPqUYd3hJwzw1G0RWIXTBbIX/jMUEUo72UiuZI64D6aZol8Jc7kukeV2nC7J7TBFkinqmShD4j8uqL8YpXQ8KiR1PF04I28KCbpMk+F3XyjBGPNGhCHlPwtYTSLUUKaoF2CaMe4LN7vXVjFOl0WiHK7LRIHiAgyJf8F5mGHNfyYJszRTUctCZa7Ag0L8HkvEeHy3ijDGvOMDGggJ4yQkV7KEXMXtnSn/Gf4T8nImRAUAjVJsK2PKIgwAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Hover and focus states for inline dropdown",
          "title": "Hover and focus states for inline dropdown",
          "src": "/static/8d438267e4796b99720af99734cc5ff8/fb070/dropdown-style-6.png",
          "srcSet": ["/static/8d438267e4796b99720af99734cc5ff8/d6747/dropdown-style-6.png 288w", "/static/8d438267e4796b99720af99734cc5ff8/09548/dropdown-style-6.png 576w", "/static/8d438267e4796b99720af99734cc5ff8/fb070/dropdown-style-6.png 1152w", "/static/8d438267e4796b99720af99734cc5ff8/c3e47/dropdown-style-6.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">Hover and focus states for inline dropdown</Caption>
    <h2 {...{
      "id": "filtering"
    }}>{`Filtering`}</h2>
    <p>{`Filtering can be used with dropdown and multi-select dropdown but not inline dropdown.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--list-box__selection`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40 / 2.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--list-box__selection svg`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--list-box__menu-icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left, padding-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--list-box__selection`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left, padding-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--list-box__selection--multi`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24 / 1.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "50%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABv0lEQVQoz5WSv0/bQBTHYy8MqDNSFtgYESNQqUOWqmv/lP4HMFQwMFZFCMpQKkWtaARUVasKJYgBqTTBJNj55bPvLv4BMbGT4VqG3uvdUUhIWHjSR9/3nu999WxfKiXiSwWkpDaLkCrZPc06N/R6vaE3m0290bhRlUsVdRXZ+o/rWPvGIm0Zbma3/utdvPs11HhErA6bvf1QUrq8YY1nc0eZsnEyY1rV2Wp1lJqgUqvN5r8ezh98/D63fWqNydlc/mffMGu7mtQVv50umNafummCjRB3HAcGQVIRgobj8KN6Ewpn52w9jibl7CdCtDvDnRZVxRJ20obvsySKoNvt8ofo9Xo8ShJeSRIwojZbI2RKzu4GQd8wi2xVLCKUPkaItVwXgiD4K+C3hGHIKaWcYMypeHYSBHBMKXvjOFOjG2KsitcYp4uE/I7CEDpxDPEAidhI9ToduBSUr66gdHHB1glRr7zr+33DV/kDpZnczvjncjnT9ryFluc99QS+7ytk7gm9DMMF03WfvT8zXmyVis9f7u89UR6F/AP/f3JCe/SdmZkenYGBeyRyDROii++mi83uIXuYUh0Y0+U5ybDXP8VrfBtyfPr+AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Spacing for multi-select dropdown with filtering",
          "title": "Spacing for multi-select dropdown with filtering",
          "src": "/static/a0b2c91215102bddfd7e38b18209ef1f/fb070/dropdown-style-7.png",
          "srcSet": ["/static/a0b2c91215102bddfd7e38b18209ef1f/d6747/dropdown-style-7.png 288w", "/static/a0b2c91215102bddfd7e38b18209ef1f/09548/dropdown-style-7.png 576w", "/static/a0b2c91215102bddfd7e38b18209ef1f/fb070/dropdown-style-7.png 1152w", "/static/a0b2c91215102bddfd7e38b18209ef1f/c3e47/dropdown-style-7.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">Spacing for multi-select dropdown with filtering | px / rem</Caption>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "163.0434782608696%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAhCAYAAADZPosTAAAACXBIWXMAAAsSAAALEgHS3X78AAAEU0lEQVRIx5VWS0hUYRS+D6+jjTkTSQlCBtUiQrGMEtRSceOqheBAilC6aCWio4LgRsGNiLppqxvRJMEKdeFKCaUI32/Hx4zvt46P8TXevvNz73DNe0c6cDj/vXPuN/853/efezlOYxEREZyeybLM4tbWFvPl5WVucnKSm52dZfdnZmY4h8Nx9aGwsDDfOjY2VoyOjhaw5OFq5PX+jMB0ra6ujsX6+nqhoqLiLvwO7oVUVlbeLi4uNpeUlISMjY2J29vbInYjAcgEpz9ju7u2Q7Wsy8tLXrk2o7Tv6+vrfw4PD/vcbnff/v7+r93d3d/w50oLjAHJsBMfMKK0srJiW1tby93Z2fmAnX2gCJCPWN+nHAAblxwVFcViYGAgl5qaKhr17F/T3RlZbW0tizU1NUJVVZUVbikvLw8qKioKys3NNcFv5eTkmPPy8oK6u7sDnU6nCUzf3ENNDB4cHPw8PDz8FWU1T01NNU9PTzdPTEx8AdBTysG1oDKtWzqBzc/Ps1LRLyvWbhAikx8dHcnHx8fMl5aWkikHpAmGJQ8NDXEKEAMEiGVjY2Me7gW7HgB6cO/k4ODgDKQkKrm+kg3JAYsqoBUM752cnMinp6cy5CR7vV4WkZOs5IqGbEHALKJMFjc3N81ofIPL5eqAFr/h4e/kuP8DpT6jHMjKvw6rq6u5/zXDHuJosVhYWCjY7XYLohmXAYpfIw8MixgOvGEPe3p6WOzt7eU7OjokyMM8MDDwrr+//z0kk6H6+Pi4DbIJR9k8QES/Jbe3t3Mggq1xdkMBurG4uCijV/Lq6ipzWs/Nzb1R+n2zbFSWIRWSjQsSkff29s4gl3Ny/JEXOQwQv90sGyTximitAF09Pz/3CVsVNwDfagF1LSMjg0WcAga4sLBggXCnsTsPfF9xNybMAdoSTzmoQFCf07W0tDQtkzxAH0KLT6DFR1g/xvoxRegySCsZ3VdAQkICi/Hx8VxSUpIUFxenHWFXRpkkSb4TMjo6qr+70tJSFjGyhIKCgntZWVnhGF0hYN70b25mZqYJ/QsAkbzhDjVji29oaOARb7W1tX1qbGwsQd8KQYjd4/HYQZAdZ/sB5UJGol+WCRS6U98plpaWlt3Ozk4ZUmFME8PENLTIWEYvBUO5qD/gNDBAEGDBcHWCBBkCPkUkP8P6AjmJ2gFreFK08xDlWSGLTdoR1jLpkfzi4kJG/5K081DXbDYbp4wkBgjNhUKTvXAnhDyDawd66QDIHEp9perQkBSy7OzsK9c4LQRKg9aCcq0YDHfIwS6bQHjnGJcKmbCYnp5OwMEpKSmSvzlI3zfa/l8jp6mpiUWwKuAT5HZXV1coXpcvMMJeYxevwf5LsB2D0mNQqnVkZEQCQaLft15+fj6HklQJmcvKyn62trY6AURnmvo4Sz1EL18qE8lYNvQZQqYBFDB0I/CBFAlSItHLSBAWCTFTNCkEcjeOL+2p8WcA1r3/F0PZxkEFpRF0AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Interaction states for multi-select dropdown with filtering",
          "title": "Interaction states for multi-select dropdown with filtering",
          "src": "/static/9e533f67f6779356b9f71dcdada03549/fb070/dropdown-style-8.png",
          "srcSet": ["/static/9e533f67f6779356b9f71dcdada03549/d6747/dropdown-style-8.png 288w", "/static/9e533f67f6779356b9f71dcdada03549/09548/dropdown-style-8.png 576w", "/static/9e533f67f6779356b9f71dcdada03549/fb070/dropdown-style-8.png 1152w", "/static/9e533f67f6779356b9f71dcdada03549/c3e47/dropdown-style-8.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">
  Interaction states for multi-select dropdown with filtering | px / rem
    </Caption>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      